<template>
  <b-row>
    <b-col cols="12">
      <b-card>
        <b-card-title>
          {{ $t('Please Select Reports Date') }}
        </b-card-title>
        <b-card-body>
          <b-row>

            <b-col cols="4">
              <label>{{ $t('Please Select Reports First Date') }}</label>
              <b-form-datepicker
                v-model="date"
                placeholder="Date"
                :state="errors.date ?false:null"
              />
              <small
                v-if="errors.date"
                class="text-danger"
              >{{ errors.date[0] }}</small>
            </b-col>
            <b-col cols="4">
              <label>{{ $t('Please Select Reports Last Date') }}</label>
              <b-form-datepicker
                v-model="date2"
                placeholder="Date"
                :state="errors.date2 ?false:null"
              />
              <small
                v-if="errors.date2"
                class="text-danger"
              >{{ errors.date2[0] }}</small>
            </b-col>
            <b-col cols="4">
              <b-col cols="9">
                <b-button
                  class="w-100 mb-1"
                  variant="gradient-success"
                  @click="moneyfn"
                >
                  {{ $t('Money') }}
                </b-button>
              </b-col>
              <b-col cols="9">
                <b-button
                  class="w-100 mb-1"
                  variant="gradient-primary"
                  @click="checksdata"
                >
                  {{ $t('checks data') }}
                </b-button>
              </b-col>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </b-col>
    <b-col
      cols="12"
    >
      <b-row>
        <b-col
          cols="12"
        >
          <b-card
            v-if="money == true "
          >
            <b-card-title>   {{ $t('totalfee') }} : {{ '$' }}{{ pay }} </b-card-title>
          </b-card>
        </b-col>
        <b-col
          v-for="(element,index) in elements"
          :key="index"
          cols="6"
        >
          <b-card
            v-if="money == false && (index === 'cars' || index === 'ebrokers' || index === 'ibrokers' ||index === 'owners')"
          >
            <b-card-title> {{ $t('checks') }} {{ $t(index) }}</b-card-title>
            <b-card-body>
              <table class="report-table">
                <tr class="table-header">
                  <td
                    colspan="2"
                    class="bg-primary text-black"
                  >
                    <span>{{ $t('head') }}</span>
                  </td>
                </tr>
                <tbody
                  v-for="el in element"
                  :key="el.name"
                >
                  <tr v-if="el.count>0">
                    <td><span>{{ el.name }}</span></td>
                    <td><span>{{ el.count }}</span></td>
                  </tr>
                </tbody>
                <tr class="bg-primary text-black table-footer">
                  <td><span>{{ $t('total') }}</span></td>
                  <td><span>{{ sum(element) }}</span></td>
                </tr>
              </table>
            </b-card-body>
          </b-card>

          <b-card
            v-if="money == true && (index === 'plate_fees' || index === 'renew_fees' || index === 'other_fees' ||index === 'pay_fees')"
          >
            <b-card-title>  {{ $t(index) }}</b-card-title>
            <b-card-body>
              <table class="report-table">
                <tr class="table-header">
                  <td
                    class="bg-primary text-black"
                  >

                    <span>{{ $t('count') }}</span>
                  </td>
                  <td
                    class="bg-primary text-black"
                  >
                    <span>{{ $t('fee') }}</span>
                  </td>
                </tr>
                <tbody
                  v-for="el in element"
                  :key="el.name"
                >
                  <tr v-if="el.count>0">
                    <td><span>{{ el.count }}</span></td>
                    <td><span>{{ el.name }}$</span></td>
                  </tr>
                </tbody>
                <tr class="bg-secondary text-black table-footer">
                  <td><span>{{ $t('totalNo') }}</span></td>
                  <td><span>{{ sum(element) }} </span></td>
                </tr>
                <tr class="bg-primary text-black table-footer">
                  <td><span>{{ $t('totalfee') }}</span></td>
                  <td><span>{{ '$' }}{{ sum2(element,index) }} </span></td>
                </tr>
              </table>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>

export default {
  data() {
    return {
      money: true,
      totalfee: 0,
      date: `${this.moment().format('YYYY-MM-DD')}`,
      date2: `${this.moment().format('YYYY-MM-DD')}`,
      errors: [],
    }
  },
  computed: {
    elements() {
      return this.$store.getters['report/GetElement']
    },
    plate_fees() {
      return this.$store.getters['report/GetElement'].plate_fees
    },
    ibrokers() {
      return this.$store.getters['report/GetElement'].ibrokers
    },
    ebrokers() {
      return this.$store.getters['report/GetElement'].ebrokers
    },
    owners() {
      return this.$store.getters['report/GetElement'].owners
    },
    pay() {
      console.log(this.$store.getters['report/GetElement'].pay)
      return this.$store.getters['report/GetElement'].pay
    },
    OwnersThrough() {
      return this.$store.getters['report/GetElement'].OwnersThrough
    },
    cars() {
      return this.$store.getters['report/GetElement'].cars
    },
  },
  watch: {
    date() {
      this.totalfee = 0
      this.$store.dispatch('report/GetElement', { date: this.date + this.date2 })
    },
    date2() {
      this.totalfee = 0
      this.$store.dispatch('report/GetElement', { date: this.date + this.date2 })
    },
  },
  created() {
    this.money = true
    this.$store.dispatch('report/GetElement', { date: this.date + this.date2 })
  },
  methods: {
    checksdata() {
      this.money = false
    },
    moneyfn() {
      this.money = true
    },
    sum(obj) {
      return obj.reduce((prev, cur) => prev + cur.count, 0)
    },
    sum2(obj, index) {
      this.totalfee = obj.reduce((prev, cur) => prev + cur.count * cur.name, 0)
      if (index === 'renew_fees') this.renew_fes = this.totalfee
      if (index === 'plate_fees') this.plate_fes = this.totalfee
      if (index === 'other_fees') this.other_fes = this.totalfee
      if (index === 'pay_fees') this.pay_fes = this.totalfee
      this.totalfee = 0
      return obj.reduce((prev, cur) => prev + cur.count * cur.name, 0)
    },
  },
}
</script>
